import React, { useEffect, useState } from "react";
import io from "socket.io-client";

const socket = io("http://localhost:4000", {
  autoConnect: true,
  transports: ["websocket"],
});

function Chat() {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const username = localStorage.getItem('username')

  useEffect(() => {
    const handleBotMessage = (msg) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: msg.text, sender: msg.sender, socketId: msg.socketId },
      ]);
    };

    socket.on('userMessage', handleBotMessage);

    return () => {
      socket.off('userMessage', handleBotMessage);
    };
  }, [socket]);

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };


  const handleSendMessage = async () => {
    if (messageInput.trim() !== "") {
      console.log("Sending message to server:", messageInput);
      socket.emit("userMessage", { text: messageInput, sender: username });
      console.log("Message sent:", messageInput);

      setMessageInput("");
    }
  };
  return (
    <div>
      <div
        style={{
          height: "300px",
          overflowY: "auto",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
            <div style={{ height: '600px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', backgroundColor: '#FFFFFF'  }}>
        {messages.map((msg, index) => (
          <div key={index} style={{ marginBottom: '10px', textAlign: msg.sender === username ? 'right' : 'left' }}>
            <strong>{msg.sender === username ? username : username === 'harshinstruct' ? 'harsh' : 'harshinstruct'}:</strong> {msg.text}

            {/* <strong>{msg.sender === username ? username : ''}:</strong> {msg.text} */}
          </div>
        ))}
      </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <input
          type='text'
          value={messageInput}
          onChange={handleInputChange}
          style={{ marginRight: "10px" }}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default Chat;
